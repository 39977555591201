import { Route, Routes } from "react-router"
import { Navbar } from "../components/Navbar/Navbar"
import { PatientsForClinicianPage } from "../pages/Patients/PatientsForClinicianPage"
import { PatientDetails } from "../pages/Patients/PatientDetails"
import { ClinicianNavbar } from "../components/Navbar/ClinicianNavbar"
import { useEffect, useState } from "react"
import { PatientProvider } from "../data_layer/context/Patient/PatientProvider"

export const ClinicianRoutes = () => {

  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [showChart, setShowChart] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const [patientGlobalList, setPatientGlobalList] = useState([]);

  return (
    <PatientProvider>
      <div className='neo_container'>
        <ClinicianNavbar
          setSearch={setSearch}
          setSort={setSort}
          setShowChart={setShowChart}
          setShowDetails={setShowDetails}
          setShowSaved={setShowSaved}
          showChart={showChart}
          showDetails={showDetails}
          showSaved={showSaved}
        />
        <main>
          {/* <ClinicianAdmin /> */}
          <Routes>
            <Route path="/patients/:patient_id"
              element={

                <PatientDetails />
              }
            />
            <Route path="/*"
              element={
                <PatientsForClinicianPage
                  search={search}
                  sort={sort}
                  showChart={showChart}
                  showDetails={showDetails}
                  showSaved={showSaved}
                  setShowSaved={setShowSaved} />
              } />
          </Routes>

        </main>
      </div>
    </PatientProvider>
  )
}