import { useEffect, useState } from "react";
import { PatientContext } from "./PatientContext";
import { FormatDate } from "../../../utilities/FormatDate";
import LocalStorage from "../../../utilities/Storage";


export const PatientProvider = ({ children }) => {
    const [patients, setPatients] = useState(() => {
        const savedPatients = LocalStorage.getItem('patients');
        return savedPatients ? JSON.parse(savedPatients) : []
    });

    useEffect(() => {
        LocalStorage.setItem('patients', JSON.stringify(patients))
    }, [patients]);


    const initPatientList = (patient) => {
        setPatients(() => [{
            id: patient.patient_id,
            patient: patient,
            currentFeedingTimestamp: patient.feedings[0].timestamp
        }]);
    }

    const addPatient = (newPatient) => {
        setPatients((prev) => [...prev, {
            id: newPatient.patient_id,
            patient: newPatient,
            currentFeedingTimestamp: newPatient.feedings[0].timestamp
        }]);
        // printCurrentList();
    }

    const removePatient = (patientId) => {
        setPatients((prev) => prev.filter((patient) => patient.id !== patientId));
        // printCurrentList()
    }

    // Function to set the current feeding for a specific patient
    const setCurrentFeeding = (patientId, feedingTimestamp) => {
        setPatients((prev) =>
            prev.map((patient) =>
                patient.id === patientId ? { ...patient, currentFeedingTimestamp: feedingTimestamp } : patient
            )
        );
        // printCurrentList()
    };

    const printCurrentList = () => {
        console.log(`Patient List: ${patients.length} patients`);
        patients.forEach((patient) => console.log({ ...patient, current: FormatDate(patient.currentFeedingTimestamp) }));
    }

    return (
        <PatientContext.Provider
            value={{
                patients,
                initPatientList,
                addPatient,
                removePatient,
                setCurrentFeeding,
            }}>
            {children}
        </PatientContext.Provider>
    );
}